import appAxios from "../components/app_config/axios";
import * as storage from "../services/migserv_storage";
import {AxiosError, isAxiosError} from "axios";
import {notification} from "antd";
import moment from "moment";
import {AllStoreSlices} from "store";
import _ from "lodash";
import {DatabaseManager} from "services/aws/db";
import {getUserIdentity} from "../services/auth";

type ApiResponse = {
  success: boolean;
  code: number | string;
  data: any;
  error?: any;
  nextStartKey?: any;
};
const db_manager = new DatabaseManager()

export interface ApiServiceStoreInterface {
  delete_approvers_testers_api: (
    manage_type: string,
    workflow: string,
    schedule_id: string,
    approver_tester_str: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  mark_as_read_api: (
    event_id: null | number,
  ) => Promise<ApiResponse>;
  create_approvers_testers_api: (
    manage_type: string,
    workflow: string,
    schedule_id: string,
    approver_tester_str: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  _approvers_testers_api: (
    method: "put" | "delete",
    manage_type: string,
    workflow: string,
    schedule_id: string,
    approver_tester_str: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  _approvers_testers_api$?: any;
  approval_status_api: (
    status: "RESCHEDULE REQUESTED" | "DROP REQUESTED",
    app_ci_id: string,
    servername: string,
    workflow: string,
    comment: string
  ) => Promise<ApiResponse>;
  get_config_api: (migtype: string[], config_properties: string[]) => Promise<ApiResponse>;
  get_questionnaire_list_api: () => Promise<ApiResponse>;
  post_approvals_update_api: (
    week_name: string,
    file_name?: string
  ) => Promise<ApiResponse>;
  linked_entities: (
    method: "get" | "post" | "put",
    schedule_id: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  v2_questionnaire_api: (
    method: "getQuestionnaireList" | "getQuestionnaireInfo" | "getQuestionsForQuestionnaire"
      | 'createOrUpdate' | 'updateQuestionnaire' | 'updateQuestion' | 'deleteQuestionnaire' | 'deleteQuestion',
    filterBy?: any | undefined,
    fields?: string[] | undefined,
    body?: any | undefined
  ) => Promise<ApiResponse>;
}

export const createApiServiceStoreSlice = (set: (state: any) => any, get: () => AllStoreSlices): ApiServiceStoreInterface => ({
  _approvers_testers_api$: null,
  mark_as_read_api: async (event_id) => {
    try {
      let result = await appAxios.request({
        method: 'PATCH',
        url: '/events',
        params: {mark_all: event_id == null ? 'true' : 'false', user: storage.get("user.isid", "")},
        data: event_id ? {event_ids: [event_id]} : {}
      })
      if (result.status == 201) {
        return {
          success: true,
          code: "201",
          data: true,
          error: null,
        };
      }
      return handleError(new Error(result.statusText))

    } catch (e) {
      return handleError(e)
    }
  },
  delete_approvers_testers_api: async (...args) => {
    return get()._approvers_testers_api("delete", ...args);
  },
  create_approvers_testers_api: async (...args) => {
    return get()._approvers_testers_api("put", ...args);
  },
  _approvers_testers_api: async (
    method: "put" | "delete",
    manage_type,
    workflow,
    schedule_id,
    approver_tester_str,
    show_notification = false
  ) => {
    try {
      let params: { [key: string]: string } = {
        //@ts-ignore
        username: storage.get("user.isid", ""),
        [manage_type.toLowerCase()]: approver_tester_str,
        workflow,
        schedule_id,
      };
      let urlParams = new URLSearchParams();
      Object.keys(params).forEach((key) => {
        urlParams.set(key, params[key]);
      });

      let result = await appAxios.request({
        url: `/${manage_type === "APPROVER" ? "approvers" : "testers"}?${urlParams.toString()}`,
        method,
        data: {},
      });
      if (show_notification) {
        notification.success({message: `${manage_type.toLowerCase()} ${method === "put" ? "added" : "removed"}`});
      }
      set({
        _approvers_testers_api$: {},
      });
      return {
        success: true,
        code: result?.status ? result.status.toString() : "200",
        data: result.data,
        error: null,
      };
    } catch (e: any | AxiosError) {
      if (show_notification) {
        notification.error({
          message: `Failed to ${method === "put" ? "add" : "remove"} ${manage_type.toLowerCase()}`,
          description: e?.message ? e.message : "",
        });
      }
      return handleError(e);
    }
  },
  approval_status_api: async (
    status: "RESCHEDULE REQUESTED" | "DROP REQUESTED",
    app_ci_id: string,
    servername: string,
    workflow: string,
    comment: string
  ) => {
    try {
      let result = await appAxios.request({
        url: `/approval_status`,
        params: {
          app_ci_id,
          servername,
          workflow,
          username: storage.get("user.isid", ""),
        },
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          status,
          comment,
        }),
      });

      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },
  get_config_api: async (migtype = [], config_properties = [""]) => {
    try {
      let result = await appAxios.request({
        url: `/config`,
        params: {
          in_migtype: migtype.length == 0 ? "" : migtype.join(","),
          in_config: config_properties.length == 0 ? "" : config_properties.join(","),
        },
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },
  get_questionnaire_list_api: async () => {
    try {
      let result = await appAxios.request({
        url: `/questions`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },

  post_approvals_update_api: async (week_name, file_name = moment().unix().toString()) => {
    try {
      let result = await appAxios.request({
        baseURL: process.env.REACT_APP_MIGSERV_API_URL,
        url: `/content`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          week_data: week_name,
          filename: file_name
        }
      });
      let timeout = setTimeout(() => {
        notification.error({
          message: `Your approvals update for week: ${week_name} might have been failed`,
          description: 'Since you selected week for the update, it has been 5 minutes. Please try again or contact admin.'
        })
      }, 12 * 60000)
      get().watch_event('APPROVAL_DATA_UPDATE', file_name, function () {
        clearTimeout(timeout)
      })
      // let result: any = {}
      return {
        success: true,
        error: null,
        data: result?.data,
        code: result?.status?.toString(),
      };
    } catch (e: any | AxiosError) {
      console.log(e)
      return handleError(e);
    }
  },
  linked_entities: async (method, schedule_id, show_notification) => {
    try {
      let result = await appAxios.request({
        url: '/approvers',
        method: method.toUpperCase(),
        params: {
          sched_id: schedule_id
        }
      })
      return {
        success: true,
        code: '200',
        error: null,
        data: result.data
      }
    } catch (e: any | AxiosError) {
      console.log(e)
      return handleError(e);
    }

  },
  v2_questionnaire_api: async (
    method,
    filterBy,
    fields,
    body) => {

    let projectionExpression = fields ? fields.join(', ') : undefined

    try {
      if (method == 'getQuestionnaireList') {

        let projectionExpression = fields
          ? fields.join(', ')
          : "PK, SK, app_ci_id, mig_type, q_status, created_at, completed_at"
        let filterExpression = `entity_type = :entityTypeValue`
        let expressionAttributeValues = {':entityTypeValue': 'Questionnaire'}

        return await db_manager.getAllObjects(
          process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
          filterExpression,
          expressionAttributeValues,
          projectionExpression,
          50)

      } else if (method == 'getQuestionnaireInfo') {

        let projectionExpression = fields
          ? fields.join(', ')
          : "PK, SK, app_ci_id, mig_type, q_status, created_at, completed_at"
        let key = {PK: _.get(filterBy, 'PK', ''), SK: _.get(filterBy, 'SK', '')}

        return await db_manager.getObjectById(
          process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
          key,
          projectionExpression)

      } else if (method == 'getQuestionsForQuestionnaire') {

        let projectionExpression = fields
          ? fields.join(', ')
          : undefined
        let keyConditionExpression = `PK = :pkValue and begins_with(SK, :skValue)`
        let expressionAttributeValues = {
          ':pkValue': _.get(filterBy, 'PK', ''),
          ':skValue': _.get(filterBy, 'SK', '')
        }

        return await db_manager.queryTable(
          process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
          keyConditionExpression,
          undefined,
          expressionAttributeValues,
          projectionExpression
        )

      } else if (method == 'createOrUpdate') {
        if (_.isObject(body)) {
          return await db_manager.createObject(process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "", body)
        }
      } else if (method == 'updateQuestion') {

        let updateExpression = 'SET '
        let key = {PK: _.get(filterBy, 'PK', ''), SK: _.get(filterBy, 'SK', '')}
        let expressionAttributeValues: any = {}
        let date_now_utc = moment.utc().toISOString()
        let updated_by = (await getUserIdentity()).email

        Object.keys(body).forEach((question_id) => {
          if (body[question_id]['q_name']) {
            updateExpression += 'q_name = :q_name, '
            expressionAttributeValues[':q_name'] = body[question_id]['q_name']
          }
          if (body[question_id]['admin_comments']) {
            updateExpression += 'admin_comments = :admin_comments, '
            expressionAttributeValues[':admin_comments'] = body[question_id]['admin_comments']
          }
          if (body[question_id]['responder_comments']) {
            updateExpression += 'responder_comments = :responder_comments, '
            expressionAttributeValues[':responder_comments'] = body[question_id]['responder_comments']
          }
          if (body[question_id]['q_status']) {
            updateExpression += 'q_status = :q_status_value, '
            expressionAttributeValues[':q_status_value'] = body[question_id]['q_status']
          }
        })
        updateExpression += 'updated_at = :updated_at, '
        expressionAttributeValues[':updated_at'] = date_now_utc

        updateExpression += 'updated_by = :updated_by, '
        expressionAttributeValues[':updated_by'] = updated_by
        updateExpression = updateExpression.trim()
        updateExpression = updateExpression.endsWith(',')
          ? updateExpression.substring(0, updateExpression.length - 1)
          : updateExpression

        return await db_manager.updateRecord(
          _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
          key,
          updateExpression,
          undefined,
          expressionAttributeValues
        )

      } else if (method == 'updateQuestionnaire') {

        let updateExpression = 'SET '
        let key = {PK: _.get(filterBy, 'PK', ''), SK: _.get(filterBy, 'SK', '')}
        let expressionAttributeValues: any = {}
        let date_now_utc = moment.utc().toISOString()
        let updated_by = (await getUserIdentity()).email

        if (body["viewed_at"]) {
          updateExpression += 'viewed_at = :viewed_at, '
          expressionAttributeValues[':viewed_at'] = body["q_status"]
        } else {

          if (body["q_status"]) {
            updateExpression += 'q_status = :q_status, '
            expressionAttributeValues[':q_status'] = body["q_status"]
          }

          updateExpression += 'updated_at = :updated_at, '
          expressionAttributeValues[':updated_at'] = date_now_utc
          updateExpression += 'updated_by = :updated_by, '
          expressionAttributeValues[':updated_by'] = updated_by
        }

        updateExpression = updateExpression.trim()
        updateExpression = updateExpression.endsWith(',')
          ? updateExpression.substring(0, updateExpression.length - 1)
          : updateExpression

        return await db_manager.updateRecord(
          _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
          key,
          updateExpression,
          undefined,
          expressionAttributeValues
        )

      } else if (method == 'deleteQuestionnaire') {

        let key = {PK: _.get(filterBy, 'PK', '')}

        await db_manager.deleteObject(
          _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
          key,
          undefined,
          undefined
        )
      } else if (method == 'deleteQuestion') {

        let key = {PK: _.get(filterBy, 'PK', ''), SK: _.get(filterBy, 'SK', '')}

        await db_manager.deleteObject(
          _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
          key,
          undefined,
          undefined
        )
      }
      return {
        success: false,
        code: 500,
        data: null,
        error: null
      }

    } catch (e: any | AxiosError) {
      console.log(e)
      return handleError(e);
    }

  }
});

const handleError = (e: any) => {
  if (isAxiosError(e)) {
    return {
      success: false,
      data: null,
      error: e.message,
      code: e?.code ? e.code : "500",
    };
  }
  return {
    success: false,
    data: null,
    error: e,
    code: "500",
  };
};
