import {useEffect} from 'react';
import {shallow} from 'zustand/shallow';
import useGlobalStore from '../../store';
import FormBuilder, {FormData} from '../FormBuilder';

const QuestionnaireForm = () => {
  const {questions, fetchQuestions, questionnaire_PK: questionnaire_PK} =
    useGlobalStore((state) => ({
        questions: state.questions,
        fetchQuestions: state.fetchQuestions,
        questionnaire_PK: state.questionnaire_PK,
      }),
      shallow)

  useEffect(() => {
    if (questionnaire_PK) {
      fetchQuestions(questionnaire_PK)
    }
  }, [questionnaire_PK])
  const formData: FormData[] = [
    // {
    //     heading: 'Form Heading 1'
    // },
    {
      questions: questions
    }
  ]

  return (
    questions.length > 0 ?
      <FormBuilder form_data={formData} config={
        {collapse_all_items: true}
      }/> : <p>No Questions found</p>

  );
};

export default QuestionnaireForm