import { useEffect, useState } from "react"
import { getQueryParams } from "services/helper"
import useGlobalStore from "store"
import { shallow } from 'zustand/shallow';
import QuestionnaireTemplateJson from './QuestionnaireTemplate.json'
import V2SingleQuestion from "./v2_SingleQuestion";
import { GlobalRender } from 'react-antd-json-renderer'
import _ from "lodash";
import { Button, Col, Divider, Row, notification } from "antd";
import { sleep } from "services/migserv_utils";
import questionnaire_version_map from "./questionnaire_version_map";
import { STATUS_TYPES, STATUS_TYPES_META } from "./types";
import { retrieveUserInfo } from "services/migserv_auth";
import { fetchAuthSession } from "@aws-amplify/core";

const V2Questionnaire = () => {
  const { v2_questionnaire_api } = useGlobalStore(
    (state) => ({
      v2_questionnaire_api: state.v2_questionnaire_api
    }),
    shallow
  );

  let [questionnaire_data, set_questionnaire_data] = useState({ original: {}, ui: {} })

  useEffect(() => {
    (async () => {
      let data = await fetch_questionnaire()
      console.log("data11111",data);
      set_questionnaire_data({ original: data, ui: await process_questionnaire_for_ui(data) })
    })()
  }, [])

  const fetch_questionnaire = async () => {
    let PK = getQueryParams()['PK']
    if (PK) {
      notification.info({ message: `Fetching Questionnaire for PK: ${PK}`, key: 'fetch' })
      let filterBy = {'PK': PK, 'SK': "Q|"}

      let response = await v2_questionnaire_api(
          'getQuestionsForQuestionnaire',
          filterBy,
          undefined,
          undefined,
          undefined)

      if (response.success) {
        console.log("Questions",response.data)
        notification.success({ message: `Fetched Questionnaire successfully`, key: 'fetch' })
        return response.data
      } else {
        notification.error({
          message: `Questionnaire doesnt exists for the ${PK}`,
          key: 'fetch'
        })
      }
    }

    return null
  }

  const on_question_submit = async (question_key, changed_values) => {
    notification.info({
      message: 'Updating questionnaire',
      key: 'update'
    })

    let filterBy = {
      'PK': getQueryParams()['PK'],
      'SK': Object.keys(changed_values)[0]}

    let result = await v2_questionnaire_api(
        'updateQuestion',
        filterBy,
        undefined,
        changed_values)

    if (result.success) {
      notification.success({
        message: 'Update Successful',
        key: 'update'
      })
      // set_questionnaire_data({ original: { ...result.data }, ui: { ...await process_questionnaire_for_ui(result.data, [question_key]) } })
    } else {
      notification.error({
        message: 'Failed to update questionnaire',
        description: result.error.toString(),
        key: 'update'
      })
    }

  }

  const process_questionnaire_for_ui = async (questionnaire, expand_keys = []) => {
    let _questionnaire = _.cloneDeep(questionnaire)
    console.info(`Questionnaire = ${_questionnaire}`)
    let logged_in_user = retrieveUserInfo()
    let ui_questionnaire = {"renderType": "stages", "renderValue": {}}

    _questionnaire.map((question, index) => {
      console.info(`Question index = ${index}, PK = ${question.PK}, SK = ${question.SK}`)
      if (question.SK.startsWith('Q')) {
        let ui_question = {}
        ui_questionnaire["renderValue"][question.SK] = {
          ...ui_questionnaire["renderValue"][question.SK],
          name: question.q_name,
          renderType: 'custom',
          panel_color: _.get(STATUS_TYPES_META, [question.q_status, 'color']),
          expanded: expand_keys.includes(question.SK),
          renderValue: <V2SingleQuestion
                questionnaire_key = {question.PK}
                question_key={question.SK}
                admin_comments={question.admin_comments}
                responder_comments={question.responder_comments}
                q_status={question.q_status}
                updated_at={question.updated_at}
                updated_by={question.updated_by}
                on_submit={(changed_values) => on_question_submit(question.SK, changed_values)}
                questionnaire_admin={logged_in_user['cognito:groups'].includes('QUESTIONNAIRE_ADMIN')}
          />
        }
      }
    })

    return ui_questionnaire
  }

  return (
      <>
        <Divider></Divider>
        <Row align="middle" justify={'center'}>
          <Col span={22}>
            <GlobalRender
                key={JSON.stringify(questionnaire_data['original'])}
                data={questionnaire_data['ui']}></GlobalRender>
          </Col>
        </Row>
      </>)
}

export default V2Questionnaire