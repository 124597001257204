import { Button, Col, Input, Modal, notification, Row, Select, Space, Tag } from "antd";
import ViewBuilder from "components/ViewBuilder";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGlobalStore from "store";
import { shallow } from "zustand/shallow";
import { EyeOutlined } from "@ant-design/icons";
import _ from "lodash";
import QuestionnaireTemplateJson from "./QuestionnaireTemplate.json";
import QuestionsArray from "./Questions.json";

import { sleep } from "../../../services/migserv_utils";
import { MigTypes } from "../../../types/migserv_types";
import {STATUS_TYPES_META} from "./types";
import moment from "moment";


const MigtypeOptions = Object.keys(MigTypes).map((key) => {
  return {
    value: key,
    label: key
  }
})

function CreateQuestionnaireForm() {

  const { v2_questionnaire_api } = useGlobalStore(
    (state) => ({
      v2_questionnaire_api: state.v2_questionnaire_api
    }),
    shallow
  );

  const [formData, setFormData] = useState({
    app_ci_id: '',
    mig_type: ''
  })

  const create_questionnaire = async () => {
    let app_ci_id = formData['app_ci_id']
    let mig_type = formData['mig_type']
    let PK = app_ci_id + '|' + mig_type
    let filterBy = {'PK': PK, 'SK': PK}
    notification.info({ message: `Creating Questionnaire for app-ci-id: ${app_ci_id} & mig_type =${mig_type}` , key: 'fetch' })

    let response = await v2_questionnaire_api('getQuestionnaireInfo', filterBy)

    if (response.success) {
      notification.success({
        message: `Questionnaire for the given App CI ID = ${app_ci_id} & Migration Type = ${mig_type} already exists. 
        Loading the existing record`,
        key: 'fetch'
      })
      return response.data
    } else {
      if (response.code === 401) {
        notification.info({ message: `Creating one, please wait.. `, key: 'fetch' })
        let response = await v2_questionnaire_api(
          'createOrUpdate',
          undefined,
          undefined,
          {
            ...QuestionnaireTemplateJson,
            PK: PK,
            SK: PK,
            app_ci_id,
            mig_type
          })

        // Insert all questions
        QuestionsArray.map((question, index) => {

          let q_response = v2_questionnaire_api(
            'createOrUpdate',
            undefined,
            undefined,
            {
              PK: PK,
              SK: `Q|${question.id}`,
              entity_type: 'Question',
              q_name: question.name,
              q_status: 'Initial',
              responder_comments: '',
              admin_comments: ''
            })
        })

        if (response.success) {
          notification.success({
            message: 'Questionnaire initialized successfully, refreshing window ...', key: 'fetch'
          })
          // Redirect to Questionnaire List screen
          await sleep(1500)
          window.location.reload()
          return
        }
      }
      notification.error({
        message: 'Some Error Occurred. Please refresh the page and try again ...',
        key: 'fetch'
      })
    }

    return null
  }

  const onFieldChange = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value })
  }

  const onSubmit = () => {
    if (formData['app_ci_id'] !== '' && formData['mig_type'] !== '') {
      create_questionnaire()
    }
  }

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        App CI Id
      </Col>
      <Col span={24}>
        <Input style={{ minWidth: '100px' }} onChange={(e: any) => onFieldChange('app_ci_id', e.target.value)}></Input>
      </Col>
      <Col span={24}>
        Migtypes
      </Col>
      <Col span={24}>
        <Select style={{ minWidth: '200px' }} options={MigtypeOptions} onChange={(value) => onFieldChange('mig_type', value)} />
      </Col>
      <Col span={24}>
        <Button onClick={onSubmit}>Submit</Button>
      </Col>
    </Row>

  )
}

function V2QuestionnaireTracking() {
  const [data, setData] = useState<any>([]);
  const [rowSelection, setRowSelection] = useState<any>([]);
  const navigate = useNavigate();
  const { v2_questionnaire_api } = useGlobalStore(
    (state) => ({
      v2_questionnaire_api: state.v2_questionnaire_api,
    }),
    shallow
  );

  useEffect(() => {
    (async () => {
      let result = await v2_questionnaire_api('getQuestionnaireList');
      if (result.success) {
        result.data = result.data?.map((item: any) => {
          if (item?.q_status) {

            return {
              ...item,
              q_status: <Tag color={_.get(STATUS_TYPES_META, [item?.q_status, 'color'])}>{item.q_status}</Tag>
            }
          }
          return item
        })
        setData(result.data);
      } else {
        notification.error({
          message: "API Error",
          description: "Failed to fetch questionnaire list. Please contact the team.",
        });
      }
    })();
  }, []);

  const muiTableBodyRowProps = ({ row }: any) => {
    return {
      onDoubleClick: (event: any) => {
        navigate("/procurement/1");
      },
      onClick: (event: any) => {
        setRowSelection({ ...rowSelection, [row.index]: rowSelection?.[row.index] ? false : true });
      },
    };
  };


  const create_questionnaire_modal = () => {
    Modal.info({
      content: <CreateQuestionnaireForm />,
      okText: 'Cancel',
      okButtonProps: {
        type: 'dashed'
      }
    })
  }

  const getDataItems = () => { 
    return data.map((item:any) => { 
      return { 
        "App CI Id": item.app_ci_id,
        "Migration Type": item.mig_type,
        "Status": item.q_status,
        "Created At": item?.created_at ? moment(item?.created_at).format("MM/DD/YY, h:mm A") : item.created_at,
        "Completed At": item?.completed_at ? moment(item?.completed_at).format("MM/DD/YY, h:mm A") : item.completed_at,
        SK: item.SK, 
        PK: item.PK, 
      }; 
    }); 
  }; 

  return (
    <Row justify={"center"} align="middle" style={{ minHeight: "8vh" }}>
      <Col span={22}>
        <Button onClick={create_questionnaire_modal}>
          Create
        </Button>
      </Col>
      <Col span={22}>
        <ViewBuilder
          view_config={{
            data: getDataItems(),
            hide_view_details: true,
            initial_state_react_material_table: {
              enableRowSelection: false,
              rowSelection,
              enableRowActions: true,
              // columnOrder: ['Actions', 'app_ci_id','mig_type','q_status', 'created_at', 'completed_at'],
              sorting: [{id: 'created_at', desc: true}],
              columnVisibility: { PK: false, SK:false },
              renderRowActions: ({ cell, row, table }: any) => {
                return (
                  <Space>
                    <EyeOutlined
                      onClick={() => navigate(`/v2/questionnaire?PK=${row.original.PK}`)} />
                  </Space>
                );
              },

            },
          }}
        />
      </Col>
    </Row>

  );
}

export default V2QuestionnaireTracking;
