import { Collapse, Input, Form, Row, Col, List, Button, Tooltip } from "antd";
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons'
import {useState} from 'react'
import { Comment } from '@ant-design/compatible';
import  { AxiosError } from "axios";
import useGlobalStore from "../../store";
import { shallow } from "zustand/shallow";
import appAxios from "../app_config/axios";
import moment from "moment";
const { Panel } = Collapse;
const { TextArea } = Input
const LongtextConversation = ({ que = {}, config = {} }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const { setAlert, fetchQuestions, questionnaire_PK } = useGlobalStore((state) => ({
        setAlert: state.setAlert,
        fetchQuestions: state.fetchQuestions,
        questionnaire_PK: state.questionnaire_PK
    }), shallow)

    const onFormChange = (e) => {
        console.log('form changed')
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        form.validateFields().then(async (val) => {
            try {
                if (que?.individual_submission_url) {
                    setLoading(true)
                        await appAxios.put(que.individual_submission_url, `${val[que.question_id]}`, {
                            headers: {
                                "Content-Type": 'text/plain'
                            }
                        })
                    form.resetFields()
                    setLoading(false)
                    await fetchQuestions(questionnaire_PK)
                }
            } catch (e) {
                if (e instanceof AxiosError) {
                    console.log('set alert triggered !')
                    setAlert(e.message, 'Error submitting your response')
                }
            }

        }).catch((errors) => {
            console.log('errors');
        })
    }

    const PanelIcon = <>
        {(que?.question_response && que?.question_response.length > 0 )?
            <Tooltip title={que?.question_response?.length + ' Responses'}>
                <CheckCircleTwoTone twoToneColor="#52c41a" />
            </Tooltip> : <ExclamationCircleTwoTone twoToneColor="red" />}

    </>
    return (
        <Collapse defaultActiveKey={[config?.collapse_all_items ? '-1' : que.question_id]} >
            <Panel header={`${que.question_name}`} key={que.question_id} extra={PanelIcon}>
                <Form form={form} onChange={onFormChange}>
                    <Row>
                        <Col span={24}>
                            <p>{que.question_descr}</p>
                        </Col>
                        <Col flex={12}>
                            <Form.Item name={que.question_id} rules={[{ required: true, message: 'input something' }]}>
                                <TextArea placeholder="Type Response" size={'middle'} autoSize={{ minRows: 2, maxRows: 6 }}></TextArea>
                            </Form.Item>


                        </Col>
                    </Row>
                    <Row gutter={[0, 10]}>
                        <Col span={24} >
                            <Form.Item>
                                <Button onClick={onFormSubmit} loading={loading} disabled={loading}>Submit Response</Button>

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {que?.question_response && que.question_response.length > 0 ?
                                <div
                                    id="scrollableDiv"
                                    style={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                        padding: '0 16px',
                                        border: '1px solid rgba(140, 140, 140, 0.35)',
                                    }}
                                >
                                    <List size="small">
                                        {que.question_response.map((response, index) => {
                                            return <List.Item key={index}>
                                                <Comment
                                                    author={<a>{response.user_id}</a>}
                                                    // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />}
                                                    content={
                                                        <p>
                                                            {response.value}
                                                        </p>
                                                    }
                                                    datetime={
                                                        <span>{response?.timestamp ? moment.utc(response.timestamp).local().format('ddd MMM DD YYYY') : 'No timestamp'}</span>
                                                    }
                                                />
                                            </List.Item>
                                        })}
                                    </List>
                                </div> : 'No Responses'}

                        </Col>
                    </Row>
                </Form>
            </Panel>
        </Collapse>
    )
}


export default LongtextConversation