import {Button, Checkbox, Col, Input, Row, Typography} from "antd";
import _ from "lodash";
import {useEffect, useState} from "react";
import {STATUS_TYPES} from "./types";
import moment from "moment";

const V2SingleQuestion = ({
                            questionnaire_key = null,
                            question_key = null,
                            admin_comments = "",
                            responder_comments = "",
                            q_status = STATUS_TYPES.INITIAL,
                            updated_by,
                            updated_at,
                            questionnaire_admin = false,
                            on_submit,
                          }) => {
  const {TextArea} = Input;
  const {Text} = Typography;
  let default_props = {
    admin_comments: admin_comments,
    responder_comments: responder_comments,
    q_status: q_status,
  };
  const [form_data, set_form_data] = useState(default_props);
  const [buttonLabel, setButtonLabel] = useState("SAVE");
  const [isEditable, setIsEditable] = useState(false)

  useEffect(() => {
    if (!questionnaire_admin &&
      form_data.q_status === STATUS_TYPES.SUBMITTED) {
      setButtonLabel("Edit")
      setIsEditable(false)
    } else {
      setButtonLabel("SAVE")
      setIsEditable(true)
    }
  }, [form_data.q_status, questionnaire_admin])

  const onSubmit = () => {
    if (buttonLabel === "Edit") {
      setIsEditable(true)
      setButtonLabel("SAVE");
    }
    let submit_data = {};

    //if the object does not exist, create it first.
    if (form_data.admin_comments !== admin_comments) {
      //Submit Admin Comment
      submit_data["admin_comments"] = form_data.admin_comments;
    }
    if (form_data.responder_comments !== responder_comments) {
      //submit Responder comment
      submit_data["responder_comments"] = form_data.responder_comments;
      if (form_data.q_status === STATUS_TYPES.INITIAL) {
        submit_data["q_status"] = STATUS_TYPES.SUBMITTED;
      }
    }
    if (form_data.q_status !== q_status) {
      //submit review q_status
      submit_data["q_status"] = form_data["q_status"];
    }
    // if (Object.keys(submit_data).length !== 0) {
    //   if (_.isFunction(on_submit)) {
    //     on_submit({ [question_key]: submit_data });
    //   }
    // }

    if (!_.isEmpty(submit_data) && _.isFunction(on_submit)) {
      on_submit({[question_key]: submit_data}).then(() => {
        set_form_data((prev) => ({
          ...prev,
          q_status: STATUS_TYPES.SUBMITTED,
        }));
        setButtonLabel("Edit");
        setIsEditable(false);
      });

    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <p>Admin's Comment</p>
        <TextArea
          rows={1}
          value={form_data.admin_comments}
          onChange={(e) =>
            set_form_data({...form_data, admin_comments: e.target.value})
          }
          style={{color: "red"}}
          disabled={
            !questionnaire_admin || form_data.q_status === STATUS_TYPES.COMPLETE
          }
          placeholder={
            !questionnaire_admin && _.isEmpty(admin_comments)
              ? "No comments are provided by admin"
              : ""
          }
        />
        {/*<Text disabled>{_.isEmpty(admin_comments.value) ? '' : moment.unix(admin_comments.timestamp).local().toString()}</Text>*/}
      </Col>
      <Col span={24}>
        {questionnaire_admin ? (
          <p>Response: </p>
        ) : (
          <p>Type your response below</p>
        )}
        <Row>
          <Col span={24}>
            <TextArea
              rows={3}
              value={form_data.responder_comments}
              onChange={(e) =>
                set_form_data({
                  ...form_data,
                  responder_comments: e.target.value,
                })
              }
              disabled={
                // questionnaire_admin ||
                !isEditable
                // form_data.q_status === STATUS_TYPES.SUBMITTED ||
                || questionnaire_admin ||
                form_data.q_status === STATUS_TYPES.COMPLETE
              }
              placeholder={
                questionnaire_admin && _.isEmpty(form_data.responder_comments)
                  ? "No comments are provided by the responder"
                  : " "
              }
            />
          </Col>
        </Row>
      </Col>
      {questionnaire_admin ? (
        <Col span={24}>
          <Checkbox
            onChange={(e) =>
              set_form_data({
                ...form_data,
                q_status: e.target.checked
                  ? STATUS_TYPES.COMPLETE
                  : STATUS_TYPES.SUBMITTED,
              })
            }
            checked={form_data.q_status === STATUS_TYPES.COMPLETE}
          >
            Mark as reviewed
          </Checkbox>
          <Checkbox
            onChange={(e) =>
              set_form_data({
                ...form_data,
                q_status: e.target.checked
                  ? STATUS_TYPES.NEED_RESPONSE
                  : STATUS_TYPES.SUBMITTED,
              })
            }
            checked={form_data.q_status === STATUS_TYPES.NEED_RESPONSE}
          >
            Need response
          </Checkbox>
        </Col>
      ) : (
        <></>
      )}
      {updated_at ? (
        <Col span={24}>
          <Text disabled>Last Updated
            at {moment(updated_at).format("MM/DD/YY, h:mm A")} by {updated_by}</Text>
        </Col>
      ) : (
        <></>
      )
      }

      <Col span={24}>
        <Button onClick={onSubmit}>{buttonLabel}</Button>
      </Col>
    </Row>
  );
};

export default V2SingleQuestion;
